const CryptoJS = require('crypto-js')
export default {
  paramGet: function (objParam) {
    let obj = {}
    for (const o in objParam) {
      if (objParam[o].constructor === Object) {
        obj = Object.assign(this.paramGet(objParam[o]), obj)
      } else {
        obj[o] = objParam[o]
      }
    }
    return obj
  },
  // 请求参数排序
  paramSort: function (obj) {
    obj = this.paramGet(obj)
    const objSortByKey = (obj) => {
      const newObj = {}
      Object.keys(obj).sort().forEach(key => {
        newObj[key] = obj[key]
      })
      return newObj
    }
    return objSortByKey(obj)
  },
  // md5 加密
  paramMd5: function (obj, privateKey, privateValue) {
    obj = this.paramSort(obj)
    let sb = ''
    // 参数分隔符
    const separator = '='
    // 参数连接符
    const joinSeparator = '&'
    // 追加key 名称
    privateKey = privateKey != null && privateKey != '' ? privateKey : ''
    // 追加key 的值
    privateValue = privateValue != null && privateValue != '' ? privateValue : ''
    for (const o in obj) {
      if (o != 'sign' && o != 'client_secret') {
        sb = sb + o + separator + obj[o] + joinSeparator
      }
    }
    if (privateKey != '' && privateValue != '') {
      sb = sb + privateKey + separator + privateValue
    }
    if (sb.endsWith(joinSeparator)) {
      sb = sb.substring(0, sb.length - 1)
    }
    const returnStr = CryptoJS.MD5(sb).toString().toLowerCase()
    return returnStr
  }

}
